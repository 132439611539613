import React, { useState } from "react";

import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Field, Label } from "../../common/fieldset";
import { Input } from "../../common/input";
import { Button } from "../../common/button";
import InputComponent from "../../components/InputComponent";

import * as projectService from "../../services/projectService";

import { message } from "antd";

function GhlCalendarSettings(props) {
  const { settings, thirdPartySettings, updateThirdPartySettings } = props;
  const [disabled, setDisabled] = useState(true);

  const [realTimeBookings, setRealTimeBookings] = useState({
    calendarSearchEnabled:
      settings?.RAGSettings?.realTimeBookingSettings?.calendarSearchEnabled ||
      false,
    daysToCheckSlotsFor:
      settings?.RAGSettings?.realTimeBookingSettings?.daysToCheckSlotsFor || 2,
  });

  const [calendarSettings, setCalendarSettings] = useState(
    thirdPartySettings?.calendarSettings?.ghlCalendarSettings || {
      calendarId: "",
    }
  );

  const enableCalendarSearch = () => {
    setDisabled(false);
    setRealTimeBookings((prevState) => ({
      ...prevState,
      calendarSearchEnabled: !prevState.calendarSearchEnabled,
    }));
  };

  const onSave = async () => {
    try {
      message.loading("Updating calendar booking",1);
      setDisabled(true);

      const response2 = await updateThirdPartySettings({
        calendarSettings: {
          calendarProvider: "GHL",
          ghlCalendarSettings: calendarSettings,
          realTimeBookings,
        }
      }, () => message.success("Calendar booking updated"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="shadow rounded m-8 flex flex-col gap-y-8 p-8">
      <div className="">
        <CheckboxGroup>
          <CheckboxField>
            <Checkbox
              name="ghl_crm_search"
              onChange={enableCalendarSearch}
              value={realTimeBookings.calendarSearchEnabled}
              checked={realTimeBookings.calendarSearchEnabled}
            />
            <Label>Calendar bookings</Label>
            <Description>
              Turn on this feature to allow bookings in your GHL Calendar
            </Description>
          </CheckboxField>
        </CheckboxGroup>
      </div>
      <div className="">
        <Field>
          <Label>Days for slots</Label>
          <Description>Number of days to look for an available slot</Description>
          <Input
            type="number"
            min="2"
            max="7"
            value={realTimeBookings.daysToCheckSlotsFor}
            onChange={(e) => {
              setDisabled(false);
              setRealTimeBookings((prevState) => ({
                ...prevState,
                daysToCheckSlotsFor: Number(e.target.value),
              }));
            }}
          />
        </Field>
      </div>
      <div className="">
        <InputComponent
          type="text"
          label="Calendar Id"
          description='Paste your "Personal" or "event" calendar Id from your GHL Calendar'
          value={calendarSettings?.calendarId}
          onChange={(e) => {
            setDisabled(false);
            setCalendarSettings((prevState) => ({
              ...prevState,
              calendarId: e.target.value,
            }));
          }}
        />
      </div>
      <div className="self-end pb-4">
        <Button onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </div>
    </div>
  );
}

function GhlContactsSearch(props) {
  const { settings, projects, activeProject, reload, setReload } = props;

  const [disabled, setDisabled] = useState(true);

  const [crmSearchEnabled, setCRMSearchEnabled] = useState(
    settings?.RAGSettings?.crmSearchEnabled
  );

  const handleSave = async () => {
    setDisabled(true);
    const data = {
      crmSearchEnabled: crmSearchEnabled,
    };
    try {
      message.loading("Updating search contacts");
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { RAGSettings: data }
      );
      if (response.status === 200) {
        message.success("Search contacts updated");
        setReload(!reload);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGHLContactsSearch = () => {
    setCRMSearchEnabled(!crmSearchEnabled);
    setDisabled(false);
  };

  return (
    <div className="shadow m-8 rounded-lg">
      <div className="p-5">
        <CheckboxGroup>
          <CheckboxField>
            <Checkbox
              name="ghl_crm_search"
              onChange={handleGHLContactsSearch}
              value={crmSearchEnabled}
              checked={crmSearchEnabled}
            />
            <Label>Search Contacts</Label>
            <Description>
              Turn on this feature to automatically use the first name of
              individuals from your GHL CRM contacts on an inbound or outbound
              call. Currently available only for the voice app
            </Description>
          </CheckboxField>
        </CheckboxGroup>
      </div>
      <div className="px-4 py-4 sm:px-6 flex justify-end">
        <Button disabled={disabled} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

function GhlCrmSettings(props) {
  const {
    projects,
    activeProject,
    settings,
    reload,
    setReload,
    thirdPartySettings,
    updateThirdPartySettings,
  } = props;

  return (
    <div className="p-8">
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6">
                Go HighLevel Configuration
              </div>
              <GhlContactsSearch
                settings={settings}
                projects={projects}
                activeProject={activeProject}
                reload={reload}
                setReload={setReload}
              />
              <div className="pb-8">
                <GhlCalendarSettings
                  settings={settings}
                  projects={projects}
                  activeProject={activeProject}
                  thirdPartySettings={thirdPartySettings}
                  updateThirdPartySettings={updateThirdPartySettings}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function GHLCRM(props) {
  const { thirdPartySettings, ...restProps } = props;

  if (thirdPartySettings?.crmSettings?.crmProvider === "ghlCRM") {
    return (
      <GhlCrmSettings {...restProps} thirdPartySettings={thirdPartySettings} />
    );
  }

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6">
                Please connect your GHL account from Integrations.
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default GHLCRM;
