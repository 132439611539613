import React, { useState, useCallback, useEffect } from "react";

import * as customActionService from "../../../services/customActions";

import ExistingEndCallConditions from "./ExistingEndCallConditions";
import NewEndConditionDialog from "./NewEndCondition";
import EnableAutoCallEnd from "./EnableAutoCallEnd";

import CallEndIcon from "../../../images/endCall.svg";

function useCustomActions(projectId, actionFunction) {
  const [actions, setActions] = useState([]);

  const fetchActions = useCallback(async () => {
    try {
      const response = await customActionService.getAllCustomActions(projectId);
      const { customActions } = response.data;

      const actions = customActions.filter(
        (action) => action.actionFunction === actionFunction
      );
      setActions(actions);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  });

  return {
    actions,
    fetchActions,
  };
}

function ParentCallEnd({ projects, activeProject, settings, refetchSettings }) {
  const { actions, fetchActions } = useCustomActions(
    projects[activeProject]._id,
    "CALLEND"
  );

  useEffect(() => {
    fetchActions();
  }, [projects, activeProject]);

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <div className="flex gap-x-2">
                  <img src={CallEndIcon} alt="End call icon" width={25} />
                  <p className="font-medium text-xl">End Call Conditions</p>
                  <div className="self-end">
                    <EnableAutoCallEnd
                      projects={projects}
                      activeProject={activeProject}
                      settings={settings}
                      refetchSettings={refetchSettings}
                    />
                  </div>
                </div>
                <NewEndConditionDialog
                  projects={projects}
                  activeProject={activeProject}
                  refetchActions={fetchActions}
                />
              </div>
              <ExistingEndCallConditions
                projects={projects}
                activeProject={activeProject}
                actions={actions}
                refetchActions={fetchActions}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ParentCallEnd;
