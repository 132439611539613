import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../common/table";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../../common/alert";
import { TbCapture } from "react-icons/tb";

import { Button } from "../../../../common/button";
import * as webhookstatusService from "../../../../services/webhookstatusService";
import { message } from "antd";
import ReactJsonPretty from "react-json-pretty";

function WebhooksRunHistory({ projects, activeProject }) {
  const [webhook, SetWebhooks] = useState([]);
  const [data, setData] = useState({});
  const [item] = useState({});
  const [reload, setReload] = useState(false);

  let [isRetryOpen, setIsRetryOpen] = useState(false);
  let [isDataExtractedOpen, setIsDataExtractedOpen] = useState(false);

  const getTime = (data) => {
    if (data === 0) return "Never";

    const epochTime = data;
    const date = new Date(epochTime);
    const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
    return dateString;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        Array.isArray(projects) &&
        activeProject >= 0 &&
        projects.length > activeProject
      ) {
        const project = projects[activeProject];

        if (project && project._id) {
          const { data } = await webhookstatusService.getAllWebhooks(
            project._id
          );
          const reversedData = data.response.reverse();
          SetWebhooks(reversedData);
        }
      }
    };

    fetchData();
  }, [reload, projects, activeProject]);

  const handleSuccess = () => {
    message.info(
      "This request was successfully posted. No further action can be performed"
    );
  };

  const handleRetry = async (item) => {
    try {
      const response = await webhookstatusService.retyWebhooks(
        item._id,
        projects[activeProject._id]
      );

      if (response.status === 200) {
        setReload(!reload);
        message.success("Success");
        setIsRetryOpen(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsRetryOpen(false);
    }
  };

  const handleJSONModal = (item) => {
    setIsDataExtractedOpen(true);
    setData(item.extracted_data);
  };

  return (
    <div>
      <div>
        {webhook.length > 0 && (
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
            <TableHead>
              <TableRow>
                <TableHeader>Webhook ID</TableHeader>
                <TableHeader>Data</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Timestamp</TableHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {webhook.length > 0 &&
                webhook.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {item.webhookId}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <TbCapture
                        className="cursor-pointer h-5"
                        onClick={() => handleJSONModal(item)}
                      />
                    </TableCell>
                    <TableCell>
                      {item.status === "failed" ? (
                        <Button
                          onClick={() => setIsRetryOpen(true)}
                          color="rose"
                        >
                          {item.status}
                        </Button>
                      ) : item.status === "finished" ? (
                        <Button onClick={handleSuccess} color="lime">
                          {item.status}
                        </Button>
                      ) : (
                        <Button outline color="light">
                          {item.status}
                        </Button>
                      )}
                    </TableCell>

                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        {getTime(item.timestamp)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}

        <Alert open={isRetryOpen} onClose={setIsRetryOpen}>
          <AlertTitle>Retry webhook</AlertTitle>
          <AlertDescription>
            Please confirm, do you want to retry the webhook now?
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={() => setIsRetryOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleRetry(item)}>Retry</Button>
          </AlertActions>
        </Alert>

        <Alert
          className="w-full overflow-auto p-4 "
          open={isDataExtractedOpen}
          onClose={setIsDataExtractedOpen}
        >
          <AlertTitle>Extracted Data</AlertTitle>
          <AlertBody>
            <ReactJsonPretty json={data} />
          </AlertBody>
          <AlertActions>
            <Button plain onClick={() => setIsDataExtractedOpen(false)}>
              Cancel
            </Button>
          </AlertActions>
        </Alert>

        <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block bg-slate-50 mt-20 mx-1 rounded-2xl shadow-md bg-white">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 p-10">
            <p>
              Here you can find other information to successfully configure your
              API connections.
            </p>
            <br />
            <div className="flex items-center space-x-2 cursor-pointer">
              {" "}
              <h3>ProjectId</h3>
            </div>
            <div></div>
            <p className="text-gray-400 my-1"></p>{" "}
          </div>
        </aside>
      </div>
    </div>
  );
}

export default WebhooksRunHistory;
