import React, { useState, useEffect } from "react";
import { Button } from "../../../common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";

import { Dialog, DialogTitle, DialogBody } from "../../../common/dialog";
import AddNewExtractionField from "./AddNewExtraction";
import TableConfirmation from "./TableConfirmation";

export function AddNewFieldDialog({ isOpen, onClose, setNewExtractions }) {
  return (
    <Dialog className="relative top-20" open={isOpen} onClose={onClose}>
      <DialogTitle>
      <div class="text-lg flex flex-row justify-between">
          <div>Add extraction field</div>
          <div class="text-lg">
            <Button plain onClick={onClose}>
              <XMarkIcon />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogBody>
        <AddNewExtractionField
          handleSecondary={onClose}
          setNewExtractions={setNewExtractions}
        />
      </DialogBody>
    </Dialog>
  );
}

export function NewFields({
  projects,
  activeProject,
  extraction,
  newExtractions,
  setNewExtractions,
  onSubmit,
  setIsOpen
}) {

  useEffect(() => {
    if (newExtractions.length === 0) {
        setIsOpen(true);
    }
  }, [])

  return (
    <>
      {newExtractions.length > 0 && (
        <TableConfirmation
          extraction={extraction}
          confirmExtraction={newExtractions}
          setConfirmExtraction={setNewExtractions}
          projects={projects}
          activeProject={activeProject}
          onAddAnotherField={() => setIsOpen(true)}
          refetchExtractions={onSubmit}
        />
      )}
    </>
  );
}
