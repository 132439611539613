import React, { useState } from "react";

import { TableComponent } from "../components/TableComponent";
import { Button } from "../../../../common/button";
import { Dialog, DialogTitle, DialogActions, DialogDescription } from "../../../../common/dialog";
import { DialogTitleWithClose } from "../components/Dialog/DialogTitleWithClose";

export function ExistingAPIs({ customApis, setActiveItem, onDelete }) {
  const [isOpen, setIsOpen] = useState(false);
  const [itemId, setItemId] = useState(null);

  return (
    <div>
      <div className="rounded-lg bg-white shadow">
        <div>
          {customApis.length > 0 && (
            <TableComponent
              data={customApis}
              headers={[
                { key: "apiName", label: "Name" },
                { key: "apiURL", label: "URL" },
                { key: "apiMethod", label: "Method" },
              ]}
              onEdit={setActiveItem}
              onDelete={(item) => {
                setIsOpen(true);
                setItemId(item._id);
              }}
            />
          )}
          <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <DialogTitle>
              <DialogTitleWithClose title="Delete API" onClose={() => setIsOpen(false)}/>
            </DialogTitle>
            <DialogActions>
              <Button outline onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onDelete(itemId);
                  setIsOpen(false);
                }}
              >
                Yes, delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
