import React from "react";
import Configuration from "./Configuration";

function ParentTriggerSMS({
  projects,
  activeProject,
  settings,
  reload,
  setReload,
  refetchSettings,
}) {
  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <Configuration
              projects={projects}
              activeProject={activeProject}
              settings={settings}
              reload={reload}
              setReload={setReload}
              refetchSettings={refetchSettings}
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default ParentTriggerSMS;
