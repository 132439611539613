import React, { useState } from "react";

import { downloadConversations } from "../../../services/conversationService";
import { message } from "antd";
import dayjs from "dayjs";

import { Button } from "../../../common/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../../../common/dialog";
import { DialogTitleWithClose } from "../../HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";
import { StartAndEndDateField } from "../components/StartAndEndDateField";
import { ConversationSourcesSelect } from "../components/ConversationSourcesSelect";

import { useDateRangeAndConverastionSource } from "../models/useDateRangeAndConversationSource";
import { createLinkAndDownloadAsCSV } from "../helpers/create_download_as_csv";
import { useCampaignTimeZone } from "../models/useSettings";

export function ConversationDownloader(props) {
  const { isOpen, onClose, projectId } = props;
  const {
    dateRange,
    setDateRange,
    conversationSource,
    setConversationSource,
    error,
    setError,
    validate,
    generateFetchConversationsPayload,
  } = useDateRangeAndConverastionSource();
  const { timezone } = useCampaignTimeZone(projectId);
  const [loading, setLoading] = useState(false);

  const downloadConversation = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    message.loading("Downloading your conversations..")

    try {
      const payload = generateFetchConversationsPayload();
      const response = await downloadConversations({
        projectId,
        body: JSON.stringify(payload),
      });
      const data = response.data;

      if (data === "") {
        setError({
          isError: true,
          message: "No conversations found for the date range",
        });
        setLoading(false);
        return;
      }

      const fileName = `${projectId}_conversations_${dayjs(
        Number(dateRange.startDate)
      ).format("YYYY-MM-DD")}-${dayjs(Number(dateRange.endDate)).format(
        "YYYY-MM-DD"
      )}`;

      createLinkAndDownloadAsCSV(data, fileName);
      message.success(`Downloaded to ${fileName}.csv`);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <Dialog size="lg" open={isOpen} onClose={onClose}>
          <DialogTitle>
            <DialogTitleWithClose
              title={`Download Conversations`}
              onClose={onClose}
            />
          </DialogTitle>
          <DialogBody>
            <div className="flex flex-col gap-y-8">
              <div>
                <StartAndEndDateField
                  error={error}
                  setError={setError}
                  setDate={setDateRange}
                  timezone={timezone}
                />
              </div>
              <ConversationSourcesSelect
                selectedSource={conversationSource}
                onSelectSource={setConversationSource}
              />
            </div>
          </DialogBody>
          <DialogActions>
            <Button outline onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={!validate()}
              onClick={downloadConversation}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
