import React from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeading,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
} from "../common/dropdown";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/16/solid";
import { useCompanyProjects } from "../Context/CompanyProjectsContext";
import {
  CheckIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";

function ProjectSelection({
  projects,
  activeProject,
  setActiveProject,
  onCreateNew,
}) {
  const { loading } = useCompanyProjects();

  const handleClick = (index) => {
    setActiveProject(index);
  };

  return (
    <Dropdown>
      <DropdownButton outline loading={loading}>
        <FolderIcon />
        {projects && projects[activeProject] && projects[activeProject].name}
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu className="z-40">
        <DropdownSection>
          <DropdownSection className="">
            <DropdownItem onClick={onCreateNew}>
              <PlusIcon /> Create new
            </DropdownItem>
          </DropdownSection>
        </DropdownSection>
        <DropdownDivider />
        <DropdownSection>
          <DropdownHeading>Workspaces</DropdownHeading>
          <DropdownSection className="max-h-80 overflow-scroll">
            {projects &&
              projects.map((project, index) => (
                <DropdownItem
                  className=""
                  onClick={() => handleClick(index)}
                  key={index}
                >
                  <div className="flex gap-x-4">
                    {project.name}
                    {project?._id === projects[activeProject]?._id && (
                      <CheckIcon width="16" />
                    )}
                  </div>
                </DropdownItem>
              ))}
          </DropdownSection>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ProjectSelection;
