import { ChevronDownIcon } from "@heroicons/react/20/solid";

import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
  } from "../../../common/dropdown.jsx";

export function WorkspaceActionsMenu(props) {
    const { onDelete, onRename } = props;
  return (
    <Dropdown>
      <DropdownButton plain className="rounded-full">
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu>
        <DropdownItem onClick={onDelete}>
          Delete
        </DropdownItem>
        <DropdownItem onClick={onRename}>
          Rename
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
