import http from "./httpService";
const apiEndpoint = "/conversation";

export function allConversation(projectId) {
    return http.get(apiEndpoint + "/project/" + projectId )
  }

  export function deleteConversation(projectId, conversationId) {
    return http.delete(apiEndpoint + "/" + "deleteConversation" + "/" + projectId + "/" + conversationId )
  }

  
export function downloadConversations({projectId, body}) {
  return http.post(`${apiEndpoint}/fetchConversationData/${projectId}`, body, {
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export function downloadExtractions({projectId, body}) {
  return http.post(`${apiEndpoint}/fetchExtractedData/${projectId}`, body, {
    headers: {
      "Content-Type": "application/json"
    }
  })
}

  