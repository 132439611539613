import React from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
} from "../../../../../common/table";
import { Button } from "../../../../../common/button";

import { PencilIcon, TrashIcon } from "@heroicons/react/16/solid";

export function TableComponent({ data, headers, onEdit, onDelete }) {
  return (
    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
      <TableHead>
        <TableRow>
          {headers.map(({ label }) => (
            <TableHeader>{label}</TableHeader>
          ))}
          <TableHeader>Action</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            {headers.map(({ key }) => (
              <TableCell>{item[key]}</TableCell>
            ))}
            <TableCell>
              <div className=" flex flex-row gap-x-2">
                <Button outline onClick={() => onEdit(item, index)}>
                  <PencilIcon />
                </Button>
                <Button outline onClick={() => onDelete(item, index)}>
                  <TrashIcon />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
