// const tabs = [
//   { name: "Phone Screening", href: "#", count: "6", current: false },
//   { name: "Interview", href: "#", count: "4", current: true },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabsWithBadge({tabs, onTabChange, className}) {
  return (
    <div className={className}>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px mx-8 flex space-x-8">
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href="#"
                aria-current={tab.current ? "page" : undefined}
                onClick={(e) => {
                    e.preventDefault();
                    onTabChange(e, {tab, index});
                }}
                className={classNames(
                  tab.current
                    ? "border-zinc-900 text-zinc-900"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current
                        ? "bg-indigo-100 text-indigo-600"
                        : "bg-gray-100 text-gray-900",
                      "ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
      </div>
  );
}
