import React, { useState, useEffect } from "react";
import { TbWebhook } from "react-icons/tb";
import { MdSettingsVoice } from "react-icons/md";
import zohoCRM from "../images/zohoCRM.svg";
import S3 from "../images/s3.svg";
import Chroma from "../images/chroma.svg";
import Play from "../images/play.svg";
import pinecone from "../images/pinecone.png";
import gohighLevel from "../images/gohighlevel.png";
import { act } from "react-dom/test-utils";
import ZohoCRM from "./HomeModule/ZohoCRM";
import GHLCRM from "./HomeModule/GHLCRM";
import { Webhooks } from "./HomeModule/WebHooks/index";
import Recording from "./HomeModule/Recording";
import S3Component from "./HomeModule/S3Component";
import PineConeComponent from "./HomeModule/PineConeComponent";
import ChromaComponent from "./HomeModule/ChromaComponent";
import * as projectService from "../services/projectService";
import PIIEncryptionComponent from "./HomeModule/PIIEncryptionComponent";
import voiceEmbedImage from "../images/voiceembed.svg";
import chatwidget from "../images/chatWidget.svg";
import message from "../images/message.svg";
import endCall from "../images/endCall.svg";
import calltransfer from "../images/callTransfer.svg";
import extraction from "../images/extraction.svg";
import customAPI from "../images/customAPI.svg";
import elevanlabs from "../../src/images/11labs.svg";
import azure from "../../src/images/azure.svg";
// import ElevanLabs from "./HomeModule/ElevanLabs";
// import AzureVoice from "./HomeModule/AzureVoice";
import VoiceEmbed from "./HomeModule/VoiceEmbed";
import ChatEmbed from "./HomeModule/ChatEmbed";
import { Heading } from "../common/heading";
import HumanAgentTransfer from "./HomeModule/HumanAgenTransfer/HumanAgentTransfer";
import TriggerSMS from "./HomeModule/TriggerSMS/ParentTriggerSMS";
import ParentTriggerSMS from "./HomeModule/TriggerSMS/ParentTriggerSMS";
import ParentCallEnd from "./HomeModule/EndCall/ParentCallEnd";
import ParentExtractionModule from "./HomeModule/Extraction/ParentExtractionModule";
import { CustomAPIs } from "./HomeModule/CustomAPIs";
import { useThirdPartySettings } from "./HomeModule/models/ThirdPartyData/useThirdPartyData";

const MODULES = {
  WEB_HOOKS: "Webhooks",
  CUSTOM_API: "Custom API",
  EXTRACTION: "Extraction",
  HUMAN_AGENT_TRANSFER: "Human Agent Transfer",
  TRIGGER_SMS: "Trigger SMS",
  END_CALL: "End Call",
  ZOHO_CRM: "Zoho CRM",
  GO_HIGH_LEVEL: "Go high level",
  RECORDINGS: "Recordings",
  VOICE_EMBED: "Voice embed",
  CHAT_EMBED: "Chat embed",
  PII_ENCRYPTION: "PII Encryption (beta)",
  S3: "S3",
  PINECONE: "Pinecone",
  CHROMADB: "ChromaDB",
};

// Generic icon component for images
const ImageIcon = ({ src, alt, width }) => (
  <img src={src} alt={alt} style={{ width: `${width}px` }} />
);

const sections = [
  // {
  //   title: "Recordings",
  {
    title: "End of conversation actions",
    items: [{ name: MODULES.WEB_HOOKS, Icon: TbWebhook }],
  },

  // },

  {
    title: "In-conversation voice actions",
    items: [
      {
        name: MODULES.CUSTOM_API,
        iconProps: { src: customAPI, alt: "Extraction", width: 30 }, //TODO
      },
      {
        name: MODULES.EXTRACTION,
        iconProps: { src: extraction, alt: "Extraction", width: 30 },
      },
      {
        name: MODULES.HUMAN_AGENT_TRANSFER,
        iconProps: {
          src: calltransfer,
          alt: "Human agent transfer",
          width: 30,
        },
      },
      {
        name: MODULES.TRIGGER_SMS,
        iconProps: { src: message, alt: "Zoho CRM", width: 30 },
      },

      {
        name: MODULES.END_CALL,
        iconProps: { src: endCall, alt: "End Call", width: 30 },
      },
      {
        name: MODULES.ZOHO_CRM,
        iconProps: { src: zohoCRM, alt: "Zoho CRM", width: 50 },
      },
      {
        name: MODULES.GO_HIGH_LEVEL,
        iconProps: { src: gohighLevel, alt: "Go High Level", width: 20 },
      },
    ],
  },

  {
    title: "Voice modules",
    items: [{ name: MODULES.RECORDINGS, Icon: MdSettingsVoice }],
  },

  {
    title: "Embed settings",
    items: [
      {
        name: MODULES.VOICE_EMBED,
        iconProps: { src: voiceEmbedImage, alt: "VoiceEmbed", width: 20 },
      },
      {
        name: MODULES.CHAT_EMBED,
        iconProps: { src: chatwidget, alt: "chatEmbed", width: 20 },
      },
    ],
  },

  {
    title: "Encryption",
    items: [{ name: MODULES.PII_ENCRYPTION, Icon: TbWebhook }],
  },

  {
    title: "Databse",
    items: [{ name: MODULES.S3, iconProps: { src: S3, alt: "S3", width: 30 } }],
  },

  {
    title: "Vector Database",
    items: [
      {
        name: MODULES.PINECONE,
        iconProps: { src: pinecone, alt: "Pinecone", width: 20 },
      },
      {
        name: MODULES.CHROMADB,
        iconProps: { src: Chroma, alt: "ChromaDB", width: 80 },
      },
    ],
  },
];

function MenuItem({ name, Icon, iconProps, isActive, onClick }) {
  return (
    <span
      className={`flex justify-between items-center border border-gray-300 p-3 rounded-xl cursor-pointer mb-2 ${
        isActive ? "bg-blue-500 text-white" : "bg-white text-black"
      }`}
      onClick={onClick}
    >
      {Icon ? <Icon /> : <ImageIcon {...iconProps} />}
      <span>{name}</span>
    </span>
  );
}

const Module = {
  [MODULES.WEB_HOOKS]: (props) => <Webhooks {...props} />,
  [MODULES.CUSTOM_API]: (props) => <CustomAPIs {...props} />,
  [MODULES.EXTRACTION]: (props) => <ParentExtractionModule {...props} />,
  [MODULES.HUMAN_AGENT_TRANSFER]: (props) => <HumanAgentTransfer {...props} />,
  [MODULES.TRIGGER_SMS]: (props) => <TriggerSMS {...props} />,
  [MODULES.END_CALL]: (props) => <ParentCallEnd {...props} />,
  [MODULES.ZOHO_CRM]: (props) => <ZohoCRM {...props} />,
  [MODULES.GO_HIGH_LEVEL]: (props) => <GHLCRM {...props} />,
  [MODULES.RECORDINGS]: (props) => <Recording {...props} />,
  [MODULES.VOICE_EMBED]: (props) => <VoiceEmbed {...props} />,
  [MODULES.CHAT_EMBED]: (props) => <ChatEmbed {...props} />,
  [MODULES.PII_ENCRYPTION]: (props) => <PIIEncryptionComponent {...props} />,
  [MODULES.S3]: (props) => <S3Component {...props} />,
  [MODULES.PINECONE]: (props) => <PineConeComponent {...props} />,
  [MODULES.CHROMADB]: (props) => <ChromaComponent {...props} />,
};

function HomeModules({ projects, activeProject, setReload, reload }) {
  const [activeItem, setActiveItem] = useState("Webhooks");
  const [settings, setSettings] = useState({});
  const [settingReload, setSettingReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [thirdPartySettings, setThirdPartySettings] = useState(null);

  const { fetchThirdPartySettings, updateThirdPartySettings } =
    useThirdPartySettings(projects[activeProject]?._id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await projectService.getSettings(
            projects[activeProject]._id
          );

          setSettings(response.data);
          setLoading(false);
        }
      } catch (error) {
        // message.error("Failed to fetch data");
      }
    };
    fetchData();
    fetchThirdPartySettings(
      (data) => setThirdPartySettings(data),
      console.error
    );
  }, [projects, reload, activeProject, settingReload]);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  if (loading) {
    return (
      <div class="flex p-5">
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10 m-5">
          <h2 class="text-lg mb-5">
            <Heading>
              Modules let you configure and expand your AI agent further
            </Heading>
          </h2>
          <p>
            Whether it's connecting with CRMs, configuring calendars, or <br />
            embedding voice on your website, we have it all covered.
          </p>
        </div>
        <div class="flex-none w-1/3 bg-white shadow-lg rounded-lg p-5 m-5">
          <div class="space-y-5 animate-pulse">
            <div class="h-6 bg-gray-300 rounded"></div>

            <div class="h-6 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        {Module[activeItem]({
          reload,
          settings,
          projects,
          setReload,
          settingReload,
          activeProject,
          projectId: projects[activeProject]._id,
          refetchSettings: () => setSettingReload((prevState) => !prevState),
          updateThirdPartySettings: (updatedSettings, successMesssage) =>
            updateThirdPartySettings(
              updatedSettings,
              () => {
                fetchThirdPartySettings(
                  (data) => setThirdPartySettings(data),
                  console.error
                );
                successMesssage();
              },
              console.error
            ),
          thirdPartySettings,
        })}
      </main>
      <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block bg-slate-50 mt-20 mx-1 rounded-2xl shadow-md bg-white">
        {sections.map((section) => (
          <div
            className="mx-auto max-w-7xl sm:px-6 lg:px-8 p-10"
            key={section.title}
          >
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <span className="bg-white pr-2 text-sm text-gray-500 mb-3">
                  {section.title}
                </span>
              </div>
            </div>
            {section.items.map((item) => (
              <MenuItem
                key={item.name}
                name={item.name}
                Icon={item.Icon}
                iconProps={item.iconProps}
                isActive={activeItem === item.name}
                onClick={() => handleItemClick(item.name)}
              />
            ))}
          </div>
        ))}
      </aside>
    </div>
  );
}

export default HomeModules;
