import React, { useState } from "react";

import { message } from "antd";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";

function EnableAgentTransfer({
  projects,
  activeProject,
  settings,
  refetchSettings,
}) {
  const [humanAgentEnabled, setHumanAgentEnabled] = useState(
    settings?.voiceChatSettings?.callTransferEnabled ?? false
  );

  const handleToggle = async () => {
    setHumanAgentEnabled(!humanAgentEnabled);

    let data = {
      websocketMaxOpenTime: settings.voiceChatSettings.websocketMaxOpenTime,
      sendWebcallWebhook: settings.voiceChatSettings.sendWebcallWebhook,
      gptSentenceEmitDelay: settings.voiceChatSettings.gptSentenceEmitDelay,
      callTransferEnabled: !humanAgentEnabled,
    };

    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { voiceChatSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      refetchSettings();
    }
  };

  return (
    <Switch
      color="emerald"
      id={`checkbox-${humanAgentEnabled}`}
      checked={humanAgentEnabled}
      onClick={handleToggle}
    />
  );
}

export default EnableAgentTransfer;
