import http from "./httpService";
const apiEndpoint = "/supwebhooks";

export function subscribetoWebhook(projectId, eventName,endpointUrl, authRequiredBool, authKey) {
    return http.post(apiEndpoint + "/" + "subscribe", {
        projectId: projectId,
        eventName : eventName,
        endpointUrl : endpointUrl,
        authRequired: authRequiredBool,
        authKey:authKey
    });
  }

  export function getAllWebhooks({projectId}) {
    return http.get(`${apiEndpoint}/getWebhooks/${projectId}`);
  }