import React, { useEffect } from "react";

import { TableComponent } from "../components/TableComponent";

const getTime = (data) => {
  if (data === 0) return "Never";

  const epochTime = data;
  const date = new Date(epochTime);
  const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
  return dateString;
};

export function SavedWebhooks({
  projects,
  activeProject,
  webhooks,
  fetchWebhooks,
}) {
  useEffect(() => {
    fetchWebhooks(projects[activeProject]._id);
  }, [projects, activeProject]);

  return (
    webhooks.length > 0 && (
      <TableComponent
        data={webhooks}
        headers={[
          {
            key: "endpointUrl",
            label: "URL",
            getValue: (data, key) => data[key],
          },
          {
            key: "authRequired",
            label: "Authenticated",
            getValue: (data, key) => data[key].toString(),
          },
          {
            key: "createdAt",
            label: "Created at",
            getValue: (data, key) => getTime(data[key]),
          },
        ]}
      />
    )
  );
}
