import React from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
} from "../../../../../common/table";
import { Button } from "../../../../../common/button";

export function TableComponent({ data, headers }) {
  return (
    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
      <TableHead>
        <TableRow>
          {headers.map(({ label }) => (
            <TableHeader>{label}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            {headers.map(({ key, getValue }) => (
              <TableCell>{getValue(item, key)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
