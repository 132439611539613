import http from "./httpService";
const apiEndpoint = "/campaigns";

export function getAllCalls(projectId) {
  return http.get(apiEndpoint + "/getAllCampaigns" + "/" + projectId);
}

export function addToCampaign(projectId, data) {
  return http.post(apiEndpoint + "/" + "createCampaign" + "/" + projectId, 
    data,
);
}

export function bulkUpload(projectId, data) {
  return http.post(apiEndpoint + "/" + "campaignBatchUpload" + "/" + projectId, 
    data,
);
}

export const deleteFromCampaign = ({projectId, campaignId}) => (
  http.delete(`${apiEndpoint}/deleteCampaign/${projectId}/${campaignId}`)
)


