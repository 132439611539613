import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import * as authService from "../services/authService";

import ProjectSelection from "../components/ProjectSelection";
import { CreateNewWorkspace } from "../HomePages/Workspaces/Create_new";


const NavigationBar = ({
  setSidebarOpen,
  projects,
  activeProject,
  setActiveProject,
  user,
  isOpen,
  setIsOpen,
}) => {
  const currentUser = authService.getCurrentUser();
  const userNavigation = [
    ...(currentUser?.isCompanyOwner
      ? [{ name: "Profile", href: `/profile` }]
      : []),
    { name: "Logout", href: `/logout` },
  ];

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center justify-between border-b border-gray-200 bg-white px-4 shadow-sm sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex flex-1 justify-end gap-x-4 lg:gap-x-6">
        <div className="hidden lg:flex lg:items-center">
          <ProjectSelection
            projects={projects}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            onCreateNew={() => setIsOpen(true)}
          />
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                aria-hidden="true"
              >
                {user.name}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={`block px-3 py-1 text-sm leading-6 text-gray-900 ${
                          active ? "bg-gray-50" : ""
                        }`}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {isOpen && (
          <CreateNewWorkspace
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          />
        )}
        
      </div>
    </div>
  );
};

export default NavigationBar;
