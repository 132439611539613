import React, { useState } from "react";
import { Divider } from "../../common/divider.jsx";
import { Button } from "../../common/button.jsx";
import {
  ArrowDownCircleIcon,
  BuildingOfficeIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";

import { ConversationDownloader } from "../Downloads/index.js";
import { DeleteWorkspace } from "../Workspaces/Delete/index.jsx";
import { RenameWorkspace } from "../Workspaces/Rename/index.jsx";
import { WorkspaceActionsMenu } from "../Workspaces/ActionMenu";

import { getCurrentUser } from "../../services/authService.js";

export function Dashboard({ projects, activeProject, company }) {
  const data =
    projects &&
    projects[activeProject] &&
    projects[activeProject].usage.current;

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);

  function formatNumber(num) {
    if (num >= 1000000) {
      return Math.floor(num / 1000000) + "M"; // Millions without decimal
    } else if (num >= 1000) {
      return Math.floor(num / 1000) + "k"; // Thousands without decimal
    }
    return num;
  }

  const currentUser = getCurrentUser();
  const isCurrentUserCompanyOwner = currentUser?.isCompanyOwner;

  return (
    <div className="flex h-screen bg-slate-50">
      <main className="flex-grow flex flex-col">
        <div className="mt-12 m-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <div className="w-6 h-6 text-gray-600">
                <FolderIcon />
              </div>
              <h3 className="font-semibold text-lg">
                {projects[activeProject]?.name}
              </h3>
              {isCurrentUserCompanyOwner && (
                <WorkspaceActionsMenu
                  onDelete={() => setIsDeleteOpen(true)}
                  onRename={() => setIsRenameOpen(true)}
                />
              )}
            </div>
            <Button color="white" onClick={() => setIsOpen(true)}>
              <ArrowDownCircleIcon /> Download
            </Button>
          </div>
          <Divider className="my-2" />
          <dl className="mt-5 grid lg:grid-cols-4 gap-5 md:grid-cols-2 sm:grid-cols-1">
            <div className="overflow-hidden rounded-lg bg-white shadow px-4 py-5 sm:p-6 ">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Calls
                </dt>
                <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                  {data ? (
                    `${Math.ceil(
                      projects[activeProject].usage.current.callTime / 60
                    )} mins`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 ">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Chats
                </dt>
                <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                  {data ? (
                    `${Math.ceil(
                      projects[activeProject].usage.current.questions
                    )} msgs`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 ">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Inbound
                </dt>
                <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                  {data ? (
                    `${data.inboundCalls} calls`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 ">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Outbound
                </dt>
                <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                  {data ? (
                    `${data.outboundCalls} calls`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 ">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  WebCall
                </dt>
                <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                  {data ? (
                    `${data.webCalls} calls`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>
          </dl>
        </div>
        <div className="mt-12 m-8">
          <div className="flex items-center gap-x-2">
            <div className="w-6 h-6">
              <BuildingOfficeIcon />
            </div>
            <h3 className="text-lg font-semibold leading-6 text-gray-900">
              Company
            </h3>
          </div>
          <Divider className="my-2" />
          <div className="mt-5 grid gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 md:w-full">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Workspaces
                </dt>
                {data ? (
                  <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                    {projects?.length} workspaces
                  </dd>
                ) : (
                  <dd className="animate-pulse bg-gray-300 h-6 w-48 rounded"></dd>
                )}
              </dl>
            </div>
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Calls
                </dt>
                {data ? (
                  <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                    {company && company.projectLevelQuotaCheck === false ? (
                      <>
                        {Math.ceil(company.usage.current.callTime / 60)} mins /{" "}
                        {Math.ceil(
                          projects?.[activeProject]?.company?.voiceSecLimit / 60
                        )}{" "}
                        mins
                      </>
                    ) : (
                      <>
                        {Math.ceil(data.callTime / 60)} mins /{" "}
                        {Math.ceil(
                          projects?.[activeProject]?.voiceSecLimit / 60
                        )}{" "}
                        mins
                      </>
                    )}
                  </dd>
                ) : (
                  <dd className="animate-pulse bg-gray-300 h-6 w-48 rounded"></dd>
                )}
              </dl>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 ">
              <dl>
                <dt className="truncate text-md font-medium text-gray-500">
                  Chats
                </dt>
                <dd className="mt-1 text-2xl font-medium tracking-tight text-gray-900">
                  {data ? (
                    `${formatNumber(
                      company.usage.current.questions
                    )} / ${formatNumber(company.allowedQuestions)} messages`
                  ) : (
                    <div className="animate-pulse bg-gray-300 h-6 w-48 rounded"></div>
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </main>
      {isOpen && (
        <ConversationDownloader
          projectId={projects[activeProject]?._id}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
      {isDeleteOpen && (
        <DeleteWorkspace
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          project={projects[activeProject]}
        />
      )}
      {isRenameOpen && (
        <RenameWorkspace
          isOpen={isRenameOpen}
          onClose={() => setIsRenameOpen(false)}
          project={projects[activeProject]}
        />
      )}
    </div>
  );
}
