import http from "../httpService";

const endPoint = "/customAPIs";

function getAllCustomAPIs({ projectId }) {
  return http.get(`${endPoint}/getAllAPIs/${projectId}`);
}

function getCustomAPI({ projectId, apiId }) {
  return http.get(`${endPoint}/getIndividualAPI/${projectId}/${apiId}`);
}

function addCustomAPI({ projectId, requestBody }) {
  return http.post(`${endPoint}/createCustomAPI/${projectId}`, requestBody);
}

function updateCustomAPI({ projectId, apiId, requestBody }) {
  return http.put(
    `${endPoint}/updateCustomAPI/${projectId}/${apiId}`,
    requestBody
  );
}

function deleteCustomAPI({ projectId, apiId }) {
  return http.delete(`${endPoint}/deleteCustomAPI/${projectId}/${apiId}`);
}

export {
    getAllCustomAPIs,
    getCustomAPI,
    addCustomAPI,
    updateCustomAPI,
    deleteCustomAPI
}
