import { useState, useEffect } from "react";

import { getSettings } from "../../../services/projectService";

export function useCampaignTimeZone(projectId) {
  const [timezone, setTimezone] = useState("UTC");

  const fetchTimezone = async () => {
    try {
      const response = await getSettings(projectId);
      const data = response.data;
      setTimezone(data.campaignSettings.campaignTimeZone);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTimezone();
  }, []);

  return {
    timezone,
  };
}
