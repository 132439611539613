import React, { useState, useEffect } from "react";

import * as humanAgentService from "../../../services/humanAgentService";

import AddNewTransfer from "./AddNewTransfer";
import ExistingAgents from "./ExistingAgents";
import TransferMessage from "./TransferMessage";

import TransferMessageIcon from "../../../images/callTransfer.svg";
import EnableAgentTransfer from "./EnableAgentTransfer";

function useHumanAgents(projectId) {
  const [humanAgents, setHumanAgents] = useState([]);

  const fetchHumanAgents = async () => {
    try {
      const response = await humanAgentService.getAllAgents(projectId);

      setHumanAgents(response.data); // Assuming response.data is the correct data format
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return {
    humanAgents,
    fetchHumanAgents,
  };
}

function HumanAgentTransfer({
  projects,
  activeProject,
  settings,
  refetchSettings,
}) {
  const { humanAgents, fetchHumanAgents } = useHumanAgents(
    projects[activeProject]._id
  );

  useEffect(() => {
    fetchHumanAgents();
  }, [projects, activeProject]);

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <div className="flex items-end gap-x-2">
                  <img
                    src={TransferMessageIcon}
                    alt="Human agents transfer icon"
                    width={30}
                  />
                  <p className="font-medium text-xl">Human Agents</p>
                  <div className="self-end">
                    <EnableAgentTransfer
                      projects={projects}
                      activeProject={activeProject}
                      settings={settings}
                      refetchSettings={refetchSettings}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-x-4">
                  <TransferMessage
                    projects={projects}
                    activeProject={activeProject}
                    settings={settings}
                    refetchHumanAgents={fetchHumanAgents}
                    humanAgents={humanAgents}
                  />
                  <AddNewTransfer
                    settings={settings}
                    projects={projects}
                    activeProject={activeProject}
                    refetchAgents={fetchHumanAgents}
                  />
                </div>
              </div>
              <ExistingAgents
                projects={projects}
                activeProject={activeProject}
                settings={settings}
                refetchHumanAgents={fetchHumanAgents}
                humanAgents={humanAgents}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default HumanAgentTransfer;
