import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import { PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as humanAgentService from "../../../services/humanAgentService";
import { message } from "antd";
import EditHumanAgent from "./EditHumanAgent";
import { Dialog, DialogTitle, DialogBody } from "../../../common/dialog";

function DeleteAgentDialog({ isOpen, onClose, onDelete }) {
  return (
    <Alert open={isOpen} onClose={onClose} size="sm">
      <AlertTitle>Delete Agent</AlertTitle>
      <AlertDescription>
        Once deleted, all agent information will be removed from our
        system, and no further calls can be transferred to this agent.
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </AlertActions>
    </Alert>
  )
}

function EditAgentDialog({ isOpen, onClose, refetchHumanAgents, projects, activeProject, item}) {
  return (
    <Dialog className="relative top-20" open={isOpen} onClose={onClose} size="2xl">
      <DialogTitle>
      <div class="text-lg flex flex-row justify-between">
          <div>Edit Agent</div>
          <div class="text-lg">
            <Button plain onClick={onClose}>
              <XMarkIcon />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogBody>
        <EditHumanAgent item={item} projects={projects} activeProject={activeProject} refetchHumanAgents={refetchHumanAgents} onCancel={onClose} />
      </DialogBody>
    </Dialog>
  )
}

function ExistingAgents({
  projects,
  activeProject,
  humanAgents,
  refetchHumanAgents,

}) {
  const [dialogState, setDialogState] = useState({
    mode: null,
    isOpen: false,
    item: null
  });

  const openEditDialog = (item) => {
    setDialogState({
      mode: "EDIT",
      isOpen: true,
      item
    });
  };

  const openDeleteDialog = (item) => {
    setDialogState({
      mode: "DELETE",
      isOpen: true,
      item
    });
  };

  const closeDialog = () => {
    setDialogState({
      mode: null,
      isOpen: false,
      item: null
    });
  }

  const handleDeleteConfirmation = async () => {
    try {
      const response = await humanAgentService.deleteHumanAgent(
        projects[activeProject]._id,
        dialogState.item._id
      );

      if (response.status === 200) {
        refetchHumanAgents();
        message.success("Agent deleted");
        closeDialog();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
    }
  };

  return (
    <div>
      {humanAgents &&
        humanAgents.humanAgents &&
        humanAgents.humanAgents.length > 0 && (
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
            <TableHead>
              <TableRow>
                <TableHeader>Agent name</TableHeader>
                <TableHeader>Phone number</TableHeader>
                <TableHeader>Keyword</TableHeader>
                <TableHeader>Action</TableHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {humanAgents &&
                humanAgents.humanAgents &&
                humanAgents.humanAgents.length > 0 &&
                humanAgents.humanAgents.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {item.name}
                    </TableCell>
                    <TableCell>{item.phoneNumber}</TableCell>
                    <TableCell>{item.searchTerm}</TableCell>

                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5 flex flex-row gap-x-2">
                          <Button outline onClick={() => openEditDialog(item)}><PencilIcon /></Button>
                          <Button outline onClick={() => openDeleteDialog(item)}><TrashIcon /></Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      {dialogState.mode === "DELETE" && <DeleteAgentDialog isOpen={dialogState.isOpen} onClose={closeDialog} onDelete={() => handleDeleteConfirmation()} />}
      {dialogState.mode === "EDIT" && <EditAgentDialog isOpen={dialogState.isOpen} onClose={closeDialog} item={dialogState.item} refetchHumanAgents={refetchHumanAgents} projects={projects} activeProject={activeProject}/>}
    </div>
  );
}

export default ExistingAgents;
