import React, { useState, useEffect } from "react";

import { ExistingAPIs } from "./IndividualAPI/ExistingAPIs";

import { Button } from "../../../common/button";

import { IndividualAPI } from "./IndividualAPI";

import { PlusCircleIcon } from "@heroicons/react/16/solid";
import { useCustomAPIs } from "./hooks/useCustomAPIs";
import { individualAPI } from "./IndividualAPI/models/individualAPI";
import customAPI from "../../../images/customAPI.svg";
import { EnableCustomAPIs } from "./EnableCustomAPIs";

const MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
  NONE: null,
};

export function CustomAPIs({
  projects,
  activeProject,
  settings,
  refetchSettings,
}) {
  const [mode, setMode] = useState({
    mode: MODE.NONE,
    item: null,
  });

  const {
    customApis,
    fetchCustomApis,
    addCustomApi,
    updateCustomApi,
    deleteCustomApi,
  } = useCustomAPIs(projects[activeProject]._id);

  useEffect(() => {
    fetchCustomApis();
  }, [projects, activeProject]);

  return (
    <main className="lg:pl-22 bg-slate-50 h-screen">
      <div className="xl:pr-96 bg-slate-50">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
          <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
            <div className="px-4 py-5 sm:px-6 flex flex-none justify-between gap-x-4">
              <div className="flex items-end gap-x-2">
                <img src={customAPI} alt="custom api" width="30" />
                <p className="font-medium text-xl">Custom APIs</p>
                <div className="self-end">
                  <EnableCustomAPIs projectId={projects[activeProject]._id} settings={settings} refetchSettings={refetchSettings} />
                </div>
              </div>
              {(!mode.mode || mode.mode === MODE.SETTINGS) && (
                  <Button
                    onClick={() => {
                      setMode({
                        mode: MODE.ADD,
                        item: individualAPI,
                      });
                    }}
                  >
                    <PlusCircleIcon />
                    add new
                  </Button>
              )}
            </div>

            {(!mode.mode || mode.mode === MODE.SETTINGS) && (
              <ExistingAPIs
                customApis={customApis}
                onDelete={(id) => deleteCustomApi(id)}
                setActiveItem={(item) => setMode({ mode: MODE.EDIT, item })}
              />
            )}
            {mode.mode === MODE.EDIT && (
              <IndividualAPI
                item={mode.item}
                onConfirm={(data) => updateCustomApi(mode.item._id, data)}
                goBack={() => setMode({ mode: MODE.NONE })}
              />
            )}
            {mode.mode === MODE.ADD && (
              <IndividualAPI
                onConfirm={(data) => addCustomApi(data)}
                item={mode.item}
                goBack={() => setMode({ mode: MODE.NONE })}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
